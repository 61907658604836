.actions {
  margin-top: 1rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }

  .cancel {
    background: #f1f2f4;
    border-radius: 4px;
    color: #6d7f92;

    &:hover {
      background: #d8dde2;
    }
  }

  .submit {
    background: #18a0fb;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    color: #ffffff;

    &:hover {
      background: #2495e1;
    }
  }
}

.fetchingContainer{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin:0 !important;
  z-index: 10;
  flex-direction: column;

  .fetching{
    display: block; 
    margin: auto;
    width: 200px;
    height: 200px;
  }

  .fetching_text {
    color: #18A0FB;
    font-size: 16px;
  }
  
}

.inputsContainer {
  label {
    width: 115px !important;
    min-width: 60px;
  }

  p {
    font-size: 10.8px !important;
    margin: 0 !important;
    position: absolute;
    left: 0;
  }

  div {
    margin: 0 !important;
    padding-bottom: 20px !important;
  }

  div>div {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    margin-left: -20px !important;
  }
}

.inputsContainerVertical {

  p {
    font-size: 10.8px !important;
    margin: 0 !important;
    position: absolute;
    left: 0;
  }

  div {
    margin: 0 !important;
    padding-bottom: 20px !important;
  }

  div>div {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.streetContainer {
  position: relative;

  .flagsSelect {
    width: 58px;
    position: absolute;
    left: 95px;
    top: -5px;
    z-index: 4;

    div>div {
      border-top-right-radius: 0% !important;
      border-bottom-right-radius: 0% !important;
    }

    span {
      display: none;
    }

  }

  .streetInputContainer {
    label {
      width: 115px !important;
    }

    p {
      font-size: 10.8px !important;
      margin: 0 !important;
      position: absolute;
      left: -58px;

    }

    div {
      margin: 0 !important;
      padding-bottom: 20px !important;
    }

    div>div {
      position: relative;
      margin: 0 !important;
      padding: 0 !important;
      margin-left: 38px !important;
      z-index: 2;
    }

    input {
      border-top-left-radius: 0% !important;
      border-bottom-left-radius: 0% !important;
    }

  }

}


.streetContainerVertical {
  position: relative;

  .flagsSelectVertical {
    width: 58px;
    position: absolute;
    left: 0px;
    top: 17px;
    z-index: 4;

    div>div {
      border-top-right-radius: 0% !important;
      border-bottom-right-radius: 0% !important;
    }

    span {
      display: none;
    }

  }

  .streetInputContainerVertical {
    label {
      width: 115px !important;
    }

    p {
      font-size: 10.8px !important;
      margin: 0 !important;
      position: absolute;
      left: -58px;

    }

    div {
      margin: 0 !important;
      padding-bottom: 20px !important;
    }

    div>div {
      position: relative;
      margin: 0 !important;
      padding: 0 !important;
      margin-left: 58px !important;
      z-index: 2;
    }

    input {
      border-top-left-radius: 0% !important;
      border-bottom-left-radius: 0% !important;
    }

  }

}