.tags_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  .tag {
    padding: 0.38rem 0.88rem;
    border: 0.5px solid #f1f2f4;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 12px;
    color: #3c4e64;
  }
}
