.option_wrapper {
  position: relative;
  overflow: hidden;
}

.option_content {
  padding: 0.7rem;
  background: #F3FAFF;
  border: 1px solid #C7E8FE;
  border-radius: 5px;

  .option_image {
    border-radius: 5px;
    height: 120px;
    width: 100%;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }


  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #29394d;
  }

  div {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #29394d;

    &.custom_details {
      overflow: hidden;
      height: 20px;

      :first-child {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .more_details {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    cursor: pointer;
  }


  &.option_approved {
    border: 1px solid #DCF5EC;
    background: #EDFAF5;
  }

  &.option_declined {
    background: #FFF4F5;
    border: 1px solid #FFE6E8;
  }

  .promoted {
    display: ruby;
    margin-bottom: 1rem;
  }
}

.autoPopup{
  display: flex;
}

.price_layout{
  position: absolute;
  top: 60%;
}